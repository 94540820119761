import React, { Component, Fragment } from "react";
import "./screencapture.scss";
import html2canvas from "html2canvas";
import ScreenCaptureIcon from "./ScreenCapture.png";
import successImage from "./success.png";
export class ScreenCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageData: null,
      isSuccess: false,
      isLoading: false,
    };
  }

  captureScreenshot = async () => {
    this.setState({ isLoading: true });
    try {
      const canvas = await html2canvas(document.body, {
        scale: window.devicePixelRatio,
        logging: false,
        useCORS: true,
        allowTaint: true,
        width: window.innerWidth,
        height: window.innerHeight,
        backgroundColor: null,
      });
      const image = canvas.toDataURL("image/png");
      this.setState({ imageData: image, isSuccess: true, isLoading: false });
      //   this.sendToBackend(image);
    } catch (error) {
      console.error("Error capturing screenshot", error);
      this.setState({ isSuccess: false, isLoading: false });
    }
  };
  render() {
    const { imageData, isSuccess, isLoading } = this.state;
    if (isLoading) {
      return <span>Capturing...</span>;
    }
    return (
      <Fragment>
        {isSuccess ? (
          <div className="screenCaptureSuccessDiv">
            <img
              id="sucessScreenCaptureImage"
              src={successImage}
              alt="Success"
            />
            <span id="sucessScreenCaptureText" className="screenCaptureSuccessText">
              Screen captured
            </span>
            <img className="capturedImage" src={imageData} alt="Screenshot" />
          </div>
        ) : (
          <Fragment>
            <div
              id="screenCaptureDiv"
              className="screencaptureIncludeDiv"
              onClick={this.captureScreenshot}
            >
              <img
                id="screenCaptureImage"
                src={ScreenCaptureIcon}
                alt="Screen Capture"
              />
              <span id="screenCaptureText" className="screenCaptureText">
                <span id="screenCaptureLink">Include screen capture</span>
              </span>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
