import {
  CheckmarkOutline,
  ThumbsDown,
  ThumbsDownFilled,
  ThumbsUp,
  ThumbsUpFilled,
} from "@carbon/icons-react";
import React, { Component } from "react";
import "./feedback-style.scss";
import { ScreenCapture } from "../../../components/screen-capture/ScreenCapture";
import { connect } from "react-redux";
import { submitFeedbackRequest } from "../../../services/feedbackService";
//import { submitFeedbackRequest } from "../../../services/feedbackService";

const mapStateToProps = (state) => {
  return {
    login_state: state.login_page,
  };
};
export class FeedbackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFeedbackThumbsUp: false,
      isFeedbackThumbsDown: false,
      detailedFeedback: {
        accuracy: { like: false, dislike: false },
        relevance: { like: false, dislike: false },
        completeness: { like: false, dislike: false },
        clinical_risk: { like: false, dislike: false },
      },
      feedbackMessage: "",
      feedbackSubmited: false,
    };
  }

  toggleFeedback = (type) => () => {
    this.setState((prev) => {
      if (type === "like") {
        return {
          isFeedbackThumbsDown: false,
          isFeedbackThumbsUp: !prev.isFeedbackThumbsUp,
        };
      } else if (type === "dislike") {
        return {
          isFeedbackThumbsUp: false,
          isFeedbackThumbsDown: !prev.isFeedbackThumbsDown,
        };
      }
    });
  };

  toggleDetailedFeedback = (type, feedbackType) => () => {
    this.setState((prevState) => {
      const newFeedback = { ...prevState.detailedFeedback };

      if (type === "like") {
        newFeedback[feedbackType].like = !newFeedback[feedbackType].like;
        newFeedback[feedbackType].dislike = false;
      } else if (type === "dislike") {
        newFeedback[feedbackType].dislike = !newFeedback[feedbackType].dislike;
        newFeedback[feedbackType].like = false;
      }
      return { detailedFeedback: newFeedback };
    });
  };
  handelInputChange = (event) => {
    this.setState({ feedbackMessage: event.target.value });
  };
  handelSubmit = () => {
    const { chatQuestion, chatResponse, login_state } = this.props;
    //const { chatQuestion, chatResponse } = this.props;
    const {
      isFeedbackThumbsUp,
      isFeedbackThumbsDown,
      detailedFeedback,
      feedbackMessage,
    } = this.state;
    const requestBody = {
      question: chatQuestion || "",
      response: chatResponse || "",
      overallFeedback: {
        thumbsUp: isFeedbackThumbsUp,
        thumbsDown: isFeedbackThumbsDown,
      },
      detailedFeedback: {
        accuracy: detailedFeedback.accuracy.like
          ? "like"
          : detailedFeedback.accuracy.dislike
          ? "dislike"
          : "neutral",
        relevance: detailedFeedback.relevance.like
          ? "like"
          : detailedFeedback.relevance.dislike
          ? "dislike"
          : "neutral",
        completeness: detailedFeedback.completeness.like
          ? "like"
          : detailedFeedback.completeness.dislike
          ? "dislike"
          : "neutral",
        clinicalRisk: detailedFeedback.clinical_risk.like
          ? "like"
          : detailedFeedback.clinical_risk.dislike
          ? "dislike"
          : "neutral",
      },
      feedbackMessage: feedbackMessage.trim(), // Ensures no unnecessary spaces
    };
    console.log("Request Body:", requestBody);
    submitFeedbackRequest(requestBody,login_state).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Feedback submitted successfully:", data);
        this.setState({ feedbackSubmited: true });
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
    this.setState({ feedbackSubmited: true });
  };
  // Function to capitalize first letter and replace underscores with spaces
  formatLabel = (label) => {
    return label
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  render() {
    const { isFeedbackThumbsDown, isFeedbackThumbsUp, feedbackSubmited } =
      this.state;
    if (feedbackSubmited) {
      return (
        <div className="feedback-container" data-html2canvas-ignore="true">
          <div className="response-feedback">
            <span className="response-feedback-icon">
              <CheckmarkOutline style={{ color: "#4CAF50" }} />
            </span>
            <span
              className="response-feedback-label"
              style={{ color: "#4CAF50" }}
            >
              Feedback submitted successfully
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="feedback-container" data-html2canvas-ignore="true">
        <div className="response-feedback">
          <span className="response-feedback-label">Was this helpful?</span>
          <span className="response-feedback-icons">
            {isFeedbackThumbsUp ? (
              <ThumbsUpFilled
                className="feedback-icon"
                onClick={this.toggleFeedback("like")}
              />
            ) : (
              <ThumbsUp
                className="feedback-icon"
                onClick={this.toggleFeedback("like")}
              />
            )}
            {isFeedbackThumbsDown ? (
              <ThumbsDownFilled
                className="feedback-icon"
                onClick={this.toggleFeedback("dislike")}
              />
            ) : (
              <ThumbsDown
                className="feedback-icon"
                onClick={this.toggleFeedback("dislike")}
              />
            )}
          </span>
        </div>
        {(isFeedbackThumbsDown || isFeedbackThumbsUp) && (
          <section className="detailed-feedback-container">
            <div className="feedback-subsection-container">
              {Object.keys(this.state.detailedFeedback).map((feedbackType) => {
                const { like, dislike } =
                  this.state.detailedFeedback[feedbackType];
                return (
                  <div className="feedback-subsection" key={feedbackType}>
                    <span className="feedback-subsection-label">
                      {this.formatLabel(feedbackType)}:
                    </span>
                    <span className="feedback-subsection-icons">
                      {like ? (
                        <ThumbsUpFilled
                          className="feedback-icon"
                          onClick={this.toggleDetailedFeedback(
                            "like",
                            feedbackType
                          )}
                        />
                      ) : (
                        <ThumbsUp
                          className="feedback-icon"
                          onClick={this.toggleDetailedFeedback(
                            "like",
                            feedbackType
                          )}
                        />
                      )}
                      {dislike ? (
                        <ThumbsDownFilled
                          className="feedback-icon"
                          onClick={this.toggleDetailedFeedback(
                            "dislike",
                            feedbackType
                          )}
                        />
                      ) : (
                        <ThumbsDown
                          className="feedback-icon"
                          onClick={this.toggleDetailedFeedback(
                            "dislike",
                            feedbackType
                          )}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <section className="feedback-input-container">
              <textarea
                className="feedbackInput"
                value={this.state.feedbackMessage}
                onChange={this.handelInputChange}
                placeholder="I’m always learning! Let me know how I can improve."
              />
            </section>
            <section className="feedback-footer-section">
              <ScreenCapture />
              <button
                type="button"
                className="feedBackButtonSubmit"
                id="feedBackButtonSubmitId"
                onClick={this.handelSubmit}
              >
                Submit
              </button>
            </section>
          </section>
        )}
      </div>
    );
  }
}

const Feedback = connect(mapStateToProps)(FeedbackPage);
export default Feedback;
