import {fixup_server_url} from "./locate-mdx-proxy-url";
import {get} from "../utils";

const FEEDBACK_API_ENDPOINT = '/api/feedback';

export function submitFeedbackRequest(props, login_page) {
  const method = 'submitFeedbackRequest'
  const request_body = JSON.stringify(props)
  console.debug(method+": request_body=" + request_body)
  const proxy_url = fixup_server_url(FEEDBACK_API_ENDPOINT)
  const cookie_session = get(login_page, 'mdxAuthenticationJson.Set-Cookie', '')
  // return a Promise
  return fetch(proxy_url,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authCookie': cookie_session
      },
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      body: request_body
    })
}