/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Component } from "react";
import { Chat } from "./Chat";
import { CommonFooter } from "../../components/common/footers/Footer";
import {TITLE_MDX_GenAI } from '../../components/common/constants';

class GenAIPage extends Component {
	
  constructor(props) {
    super(props);
    this.state = {
      chatAnswers: JSON.parse(sessionStorage.getItem("chatAnswers")) || [],
      chatHistory: JSON.parse(sessionStorage.getItem("chatHistory")) || [],
    };
  }
  
  // Save chat state to session storage when unmounting
  componentWillUnmount() {
    const { chatAnswers, chatHistory } = this.state;
    sessionStorage.setItem("chatAnswers", JSON.stringify(chatAnswers));
    sessionStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  }
  
  // Update state from child component
  handleChatStateUpdate = (answers, chatHistory) => {
    this.setState({ chatAnswers: answers, chatHistory });
  };
  
  // Set the document title when the component mounts
  componentDidMount() {
    document.title = TITLE_MDX_GenAI;
  }

  render() {
	const { chatAnswers, chatHistory } = this.state;

    return (
      <div className="main-container">
        <div className="empty-container"></div>
        <div className="main-content">
          <div
            className=""
            style={{ height: "100%", overflowY: "auto", padding: "0 1rem" }}
          >
            <div className="mdx-header border-line">
              <h1 className="heading-color heading-font gen-ai-page-titles">
                MDX GenAI
              </h1>
            </div>
            <Chat 
              answers={chatAnswers}
              chatHistory={chatHistory}
              onStateUpdate={this.handleChatStateUpdate}
            />
            <CommonFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default GenAIPage;
