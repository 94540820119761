/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import { v4 as uuidv4 } from "uuid";
import { fixup_server_url } from "../../services/locate-mdx-proxy-url";

// Function to hyperlink answers
export function hyperlinkAnswer(response) {

    const divId = `-${uuidv4()}-`;
    let answerHtml =  "<div>\n" + response.answer;
    let formattedHtml = "<div>\n"

    if (response.sources !== undefined) {
        let startIdx = 0;
        for (let i = 0; i < response.sources.length; i++) {
            const source = response.sources[i];
            const docTitle = response.sources[i].sourceDocument.title;

            formattedHtml = formattedHtml +  response.answer.substring(startIdx, source.answerLinkage.begin);

            formattedHtml = `${formattedHtml}<span style="background-color:#f5f3c7">"${response.answer.substring(source.answerLinkage.begin, source.answerLinkage.end)}"</span> `;

            const hyperlink = `<a href="javascript:void(0)" onclick="document.getElementById('detail${divId}${i}').style.display = 'block'; 
            document.getElementById('overlay${divId}${i}').style.display = 'block';">[${docTitle}]</a>`;

            formattedHtml = formattedHtml +  " " + hyperlink;

            if (i < response.sources.length -1) {
                formattedHtml = formattedHtml + response.answer.substring(source.answerLinkage.end, response.sources[i + 1].answerLinkage.begin);
                startIdx = response.sources[i + 1].answerLinkage.begin;
            } else {
                formattedHtml = formattedHtml + response.answer.substring(source.answerLinkage.end);
            }
        }
    }

    if (response.type === 'drug_interaction') {
        let ddiUrl =  "/#/drugInteraction?";
        let drugInfo = response['drugInteractionInfo']['drugInfo'];

        if (drugInfo.length > 0) {
            const drugNames = drugInfo.map((drug) => drug.drugName).join(', ');

            for (let i = 0; i < drugInfo.length; i++) {
                let drugId = drugInfo[i]['drugId'];
                let drugName = drugInfo[i]['drugName'];

                ddiUrl += "&drug_ids=" + drugId + "&drug_names=" + encodeURIComponent(encodeURIComponent(drugName));
            }

            answerHtml += "<br>Link to the Micromedex drug interaction page for " + drugNames + ": <a href=\"" + fixup_server_url(ddiUrl) + 
                          "\" target='_blank'>Drug interaction information</a>";
        }
    }
    else if (response.type === 'iv_compatibility') {
        let ivCompUrl = "/#/ivCompatibility?";
        let drugInfo = response['ivCompatibilityInfo']['drugInfo'];
        let solutionInfo = response['ivCompatibilityInfo']['solutionInfo'];

        let drugNames = [];
        let solutionNames = [];
        if (drugInfo.length > 0 || solutionInfo.length > 0) {
            drugNames = drugInfo.map((drug) => drug.drugName);
            for (let i = 0; i < drugInfo.length; i++) {
                let drugId = drugInfo[i]['drugId'];
                let drugName = drugInfo[i]['drugName'];
                ivCompUrl += "&drug_ids=" + drugId + "&iv_drug_names=" + encodeURIComponent(encodeURIComponent(drugName));
            }

            solutionNames = solutionInfo.map((solution) => solution.solutionName);
            for (let i = 0; i < solutionInfo.length; i++) {
                let solutionId = solutionInfo[i]['solutionId'];
                let solutionName = solutionInfo[i]['solutionName'];
                ivCompUrl += "&iv_solution_ids=" + solutionId + "&iv_solution_names=" + encodeURIComponent(encodeURIComponent(solutionName));
            }

            let compoundNames = drugNames.concat(solutionNames).join(', ');
            answerHtml += "<br>Link to the Micromedex IV compatibility information for " + compoundNames + ": <a href=\"" + fixup_server_url(ivCompUrl) + 
                          "\" target='_blank'>IV compatibility information</a>";
        }
    }

    if (formattedHtml === "<div>\n") {
        answerHtml = answerHtml + "</div>";
    } else {
        answerHtml = formattedHtml + "</div>";
    }

    if (response.sources !== undefined) {
        for (let i = 0; i < response.sources.length; i++) {
            let evidenceText = response.sources[i].evidenceSummary.text;
            const beforeText = evidenceText.slice(0, response.sources[i].evidenceSummary.highlightBegin);
            const highlightText = evidenceText.slice(response.sources[i].evidenceSummary.highlightBegin, response.sources[i].evidenceSummary.highlightEnd);
            const afterText = evidenceText.slice(response.sources[i].evidenceSummary.highlightEnd);
            const highlightSection = response.sources[i].evidenceSummary.highlightSection;

            const docTitle = response.sources[i].sourceDocument.title;

            let detailDiv = `
            <div id="detail${divId}${i}" style="
                display: none;
                position: fixed;
                top: 50%;
                transform: translate(-0%, -40%);
                border: 1px solid #000;
                background-color: #fff;
                white-space: normal;
                padding: 20px;
                max-width: 50%;
                max-height: 80%;
                overflow-y: auto;
                z-index: 1000;">
                <b>${docTitle}</b>
                &nbsp;&nbsp; - &nbsp;&nbsp;
                <b>${highlightSection}</b>
                <br /><br />
                <div style="background-color: #f6f6f6;">${beforeText}<div style="background-color: #f5f3c7;">${highlightText}</div>${afterText}</div>
                <a href="javascript:void(0)" onclick="document.getElementById('detail${divId}${i}').style.display = 'none'; document.getElementById('overlay${divId}${i}').style.display = 'none'; ">Close</a>
            </div>
            `;

            let overlayDiv = `
            <div id="overlay${divId}${i}" onclick="hidePopup('detail${divId}${i}')" style="
                display: none;
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 999;">
            </div>
            `;
            answerHtml = answerHtml + detailDiv + "\n" + overlayDiv + "\n";

            document.addEventListener('keydown', function(event) {
                if (event.key === "Escape") {
                    const overlay = document.getElementById(`overlay${divId}${i}`);
                    const detail = document.getElementById(`detail${divId}${i}`);
                    if (overlay) overlay.style.display = 'none';
                    if (detail) detail.style.display = 'none';
                }
            });
        }
    }

    if (response.preprocessedQuestion !== undefined && (response.selectedCorpus === undefined || response.selectedCorpus === "" )) { 
        answerHtml = answerHtml.replace("<div>", "<div>Answering: \"" + response.preprocessedQuestion.trim() + "\"\n");
    }
    else if (response.preprocessedQuestion !== undefined && response.selectedCorpus !== undefined && response.selectedCorpus !== "") {
        let corpusDisplayName = "";
        if (response.selectedCorpus === "drug") {
            corpusDisplayName = "Micromedex Drug Content";
        }
        else if (response.selectedCorpus === "tox") {
            corpusDisplayName = "Micromedex Toxicology Content";
        }
        answerHtml = answerHtml.replace("<div>", "<div>Answering: \"" + response.preprocessedQuestion.trim() + 
                        "\"\n Searching: " + corpusDisplayName + "\n");
    }

    return markdownToHtml(answerHtml);
}

// Helper function to convert markdown to HTML
export function markdownToHtml(answer) {
    let markdown = answer;
    if (markdown != null) {
        markdown = markdown.replace(/^#### (.*?)(\n|$)/gm, '<h4>$1</h4>');
        markdown = markdown.replace(/^### (.*?)(\n|$)/gm, '<h3>$1</h3>');
        markdown = markdown.replace(/^## (.*?)(\n|$)/gm, '<h2>$1</h2>');
        markdown = markdown.replace(/^# (.*?)(\n|$)/gm, '<h1>$1</h1>');
        markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>');
    }
    return markdown;
}

// Function to parse structured answer into HTML
export function parseStructureAnswerToHtml(data) {
    return {
        answerHtml: hyperlinkAnswer(data)
    };
}

// Function to parse plain answer into HTML
export function parseAnswerToHtml(answer) {
    const fragments = answer.split(/\[([^\]]+)\]/g);
    return {
        answerHtml: fragments.map((frag, i) => i % 2 === 0 ? frag : `<a href="${frag}">${frag}</a>`).join('')
    };
}
