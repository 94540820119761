/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2023                                      */
/*                                                                   */
/* ***************************************************************** */

import React from 'react';
import { Route } from 'react-router-dom';
import { get } from "../utils"
import { DESIRED_LOCATION_HASH } from "../utils/commonUtils";
import {
    makeIVCompShowUnableToDeleteMessageAction
} from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-summary-page/iv-compatibility-summary-utils";
import {initializeScrollOffset} from "../utils/scrollUtils";

export const UserProtectedRoute = ({
  component: Component,
  redux_store: store,
  ...rest
}) => {
    //workaround for Safari browser
    initializeScrollOffset();
    return (
        <Route
            {...rest}
            render = {
                props => {
                    if (get(store.getState().login_page, 'mdxAuthenticationJson.Set-Cookie', '')) {
                      if (rest.path === '/ivCompatibility') {
                        // DE76626 hide "Cannot Delete" dialog when switching into IV compat summary page
                        store.dispatch(makeIVCompShowUnableToDeleteMessageAction(false))
                      }
                      
                      if (rest.path === "/gen-ai") {
                        //if GenAi page is disabled show disabled route message.
                        if (get(store.getState().gateway_login_page, 'mdxAuthenticationJson.Client_Config.enableMdxGenAI', false)) {
                          return <Component {...props} />;
                        }
                        else {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100vh",
                                textAlign: "center",
                                backgroundColor: "#f8f9fa",
                                color: "#333",
                                fontFamily: "Arial, sans-serif",
                                padding: "20px",
                              }}
                            >
                            <h1
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                marginBottom: "1rem",
                              }}
                            >
                            This route is disabled
                            </h1>
                            <p
                              style={{
                                fontSize: "0.6rem",
                                color: "#555",
                              }}
                            >
                            Kindly take a subscription to access the page.
                            </p>
                            </div>
                          );
                        }
                      }
                      
                      return <Component {...props} />
                    }
                    localStorage.setItem(DESIRED_LOCATION_HASH, window.location.hash);
                    props.history.push('/login')
                }
            }
        />
    );
}
