/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, { Component, createRef } from "react";
import "./chatstyle.scss";
import { Clean, SendFilled } from "@carbon/icons-react";
import { fixup_server_url } from "../../services/locate-mdx-proxy-url";
import { parseStructureAnswerToHtml } from "./AnswerParser";
import Feedback from "./feedback-component/Feedback";

export class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: props.answers || [],
      chatHistory: props.chatHistory || [],
      isLoading: false,
      error: null,
      inputValue: "",
    };
    this.chatRef = createRef();
  }

  clearChat = () => {
    this.setState({ answers: [], chatHistory: [] });
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleSendClick = () => {
    const { inputValue } = this.state;
    this.makeApiRequest(inputValue.trim());
    this.setState({ inputValue: "" });
  };

  async makeApiRequest(question) {
    if (!question) {
      this.setState({ error: "Please enter a valid question." });
      return;
    }

    this.setState((prevState) => ({
      error: "",
      isLoading: true,
      answers: [...prevState.answers, [question, ""]],
      chatHistory: [
        ...prevState.chatHistory,
        { role: "user", content: question },
      ],
    }));

    const requestPayload = JSON.stringify({
      history: [...this.state.chatHistory, { role: "user", content: question }],
      approach: "omni",
      contentsets:["DRUGPOINTS","DRUGDEXEVALS","DRUGINTERACTIONS","IVINDEX","TOXICOLOGY"],
    });

    try {
      const proxy_url = fixup_server_url(`/api/streaming/genai`);

      const response = await fetch(proxy_url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: requestPayload,
      });
      
      if (response.status === 403) {
        throw new Error("Forbidden: You do not have access to this resource.");
      } else if (response.status === 404) {
        throw new Error(
          "Not Found: The requested resource could not be located."
        );
      } else if (response.status === 500) {
        throw new Error(
          "Internal Server Error: The server encountered an error."
        );
      } else if (!response.ok) {
        throw new Error(
          `Unexpected Error: Failed to fetch response (Status: ${response.status}).`
        );
      }
      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = (await reader?.read()) || {};
        if (done) {
          console.debug("Reading compleated", done);
          break;
        }
        buffer += decoder.decode(value);
        const bufferString = String(buffer);
        const answerLines = bufferString.split("\n");

        if (
          answerLines &&
          Array.isArray(answerLines) &&
          answerLines.length > 0
        ) {
          let lastLine = answerLines[answerLines.length - 1] || "";
          if (lastLine === "" && answerLines.length > 1) {
            lastLine = answerLines[answerLines.length - 2];
          }
          let answerJson = { chatId: "", answer: "" };
          try {
            answerJson = JSON.parse(lastLine);
            console.debug("generating senetized html...", done);
            const sanitizedHtml =
              parseStructureAnswerToHtml(answerJson).answerHtml;

            this.setState((prevState) => {
              const answers = [...prevState.answers];

              answers[answers.length - 1][1] = sanitizedHtml;

              return {
                answers,
                chatHistory: [
                  ...prevState.chatHistory,
                  { role: "assistant", content: sanitizedHtml },
                ],
              };
            });
            buffer = ""; // Clear buffer after successful parse
          } catch {
            console.log(
              "Found an incomplete json response. This may be innocuous"
            );
          }
        }
      }
    } catch (err) {
      console.error("API Error:", err);
      this.setState((prevState) => ({
        error: err.message,
        isLoading: false,
        chatHistory: [
          ...prevState.chatHistory,
          { role: "assistant", content: err },
        ],
      }));
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
	if (
      prevState.answers !== this.state.answers ||
      prevState.chatHistory !== this.state.chatHistory
    ) {
      // Pass state updates to parent
      if (this.props.onStateUpdate) {
        this.props.onStateUpdate(this.state.answers, this.state.chatHistory);
      }
    }
    
    if (prevState.answers !== this.state.answers) {
      const chatElement = this.chatRef.current;
      if (chatElement) {
        chatElement.scrollTop = chatElement.scrollHeight;
      }
    }
  }

  render() {
    const { answers, isLoading, error, inputValue } = this.state;

    return (
      <div className="container">
        <div className="stack">
          <div className="stackItem">
            <div className="chatRoot">
              <div className="chatContainer">
                <div ref={this.chatRef} className="chatMessageStream">
                  {answers.map((answer, index) => (
                    <div key={index}>
                      {answer[0] && (
                        <div className="message user-message">
                          <div className="message-content">
                            <span>{answer[0]}</span>
                          </div>
                        </div>
                      )}
                      {answer[1] && (
                        <div className="message bot-message">
                          <div
                            className="answerText"
                            dangerouslySetInnerHTML={{ __html: answer[1] }}
                          />
                          <Feedback key={index} chatQuestion={answer[0]} chatResponse={answer[1]}/>
                        </div>
                      )}
                    </div>
                  ))}
                  {isLoading && (
                    <div
                      className="chatMessageGptMinWidth"
                      style={{ paddingLeft: "2.7rem" }}
                    >
                      <div className="progress-bar">
                        <div className="progress"></div>
                      </div>
                    </div>
                  )}
                  {error && (
                    <div className="chatMessageGptMinWidth">
                      <span className="error-text">Error: {error}</span>
                    </div>
                  )}
                </div>

                <div className="chatInputBox">
                  <button className="clearChat" onClick={this.clearChat}>
                    <Clean size={20} />
                  </button>
                  <input
                    id="userChatInput"
                    className="chatInput"
                    placeholder="Type a new question..."
                    value={inputValue}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                    disabled={isLoading}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.handleSendClick();
                      }
                    }}
                  />
                  <SendFilled
                    size={32}
                    className="sendBtn"
                    style={{ fill: "#495057", transition: "all 0.3s ease" }}
                    onClick={this.handleSendClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
