/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Fragment} from 'react';

import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  HeaderSideNavItems,
  SideNavItems,
  SkipToContent
} from "@carbon/react";

import CommonOverflowMenu from "../components/common-overflow-menu/CommonOverflowMenu";

import { makeSetChatbotReturnTarget } from '../app-state-redux';

import {Route} from 'react-router-dom';

import {get} from "../utils";

import SideNav from "../components/overriden/menu/SideNav";

let isSameLocationMenuClicked = false;

export function isChatBotAsHomepage(homePageArray) {
  if (homePageArray !== null && homePageArray.length > 0) {
    const page = homePageArray.find(page => page.chatBotAsHomepage !== undefined);
    return page ? page.chatBotAsHomepage : false;
  }
  return false;
}

export function isSearchAsHomePage(homePageArray) {
  if (homePageArray !== null && homePageArray.length > 0) {
    const page = homePageArray.find(page => page.searchAsHomePage !== undefined);
    return page ? page.searchAsHomePage : false;
  }
  return true;
}

export const mapStateToProps = state => {
  const homePageArray = get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.homepage', []);
  return {
    subscription_state: get(state.login_page, 'mdxAuthenticationJson'),
    Gatewaypage_state: get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.custom_ui.header_title_suffix'),
    ChatBotAsHomepage_state: get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.chatBotAsHomepage') === undefined ?
                             isChatBotAsHomepage(homePageArray):
                             get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.chatBotAsHomepage', false),
    SearchAsHomePage_state: get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.chatBotAsHomepage') === undefined ?
                            isSearchAsHomePage(homePageArray) : true,
    EnableMdxGenAI_state: get(state.gateway_login_page, 'mdxAuthenticationJson.Client_Config.enableMdxGenAI', false)
  }
}

export const mapDispatchToProps = dispatch => {
  return {
    onReturnToChatbotPage: (return_target, currentLocation) => {
      dispatch(makeSetChatbotReturnTarget(return_target, currentLocation));
    }
  }
}

export function closeSideNav(event, props, isSideNavExpanded, onClickSideNavExpand) {
  const return_target = event.currentTarget.hash.replace('#','');
  const currentLocation = props.location.pathname;
  if (return_target === currentLocation) {
    isSideNavExpanded = false;
    isSameLocationMenuClicked = true;
  }
  onClickSideNavExpand();
}

export function genericRender(props, isChatbot) {
  const hasDrugInteractionsString = get(props.subscription_state, 'drugInteractions', 'no');
  const hasDrugInteractions = hasDrugInteractionsString==='yes'
  const chatBotAsHomepage = get(props.ChatBotAsHomepage_state);
  const searchAsHomePage = get(props.SearchAsHomePage_state);
  const hasIvCompatibilityString = get(props.subscription_state, 'IVCompatibilityYSiteAdmixtureSyringe', 'no');
  const hasIvCompatibility = hasIvCompatibilityString==='yes'
  //by default true(may change later)
  const showMdxGenAIPage=get(props.EnableMdxGenAI_state);
  const current_route = get(props,'location.pathname','');
  const headerClassName = isChatbot ? "chatbot-custom-header" : "general-header";

  // M1468 MDX Rebranding 2.0
  const headerPrefix = isChatbot ? "" : "Merative";
  const headerSuffix = get(props.Gatewaypage_state);
  const headerBrand = isChatbot ? "Micromedex® Assistant" : "Micromedex®";
  const header = ((headerSuffix !== undefined) && (headerSuffix.length > 0)) ? headerBrand + " + " + headerSuffix : headerBrand;

  const return_target = props.location.pathname;
  const current_location = props.location;

  // Based on client-config properties, setting appropriate page on clicking header
  var pageRedirect;
  if(chatBotAsHomepage){
      pageRedirect = "#/chatbot";
  }
  else if(searchAsHomePage){
      pageRedirect = "#/home";
  }
  else if(hasDrugInteractions){
    pageRedirect = "#/drugInteractionSearch";
  }
  else if(hasIvCompatibility){
    pageRedirect = "#/ivCompatibilitySearch";
  }
  else{
    pageRedirect = "#/errorpage";
  }
  
  return (
    <HeaderContainer render={({ isSideNavExpanded, onClickSideNavExpand  }) => (
      <>
        <Header aria-label="Merative Platform Name" className={headerClassName}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label = "Open menu"
            onClick = {onClickSideNavExpand}
            isActive = {isSideNavExpanded}
          />
          <HeaderName href={pageRedirect} onClick={() => isSideNavExpanded && onClickSideNavExpand()} prefix={headerPrefix}>
            {header}
          </HeaderName>
          <HeaderNavigation aria-label = "Micromedex® Assistant">
            { searchAsHomePage && (
            <HeaderMenuItem href="#/home"
                            onClick={() => props.onReturnToChatbotPage(return_target, current_location)}
            >Home</HeaderMenuItem>)
            }
            {
              hasDrugInteractions
                ?
                (<HeaderMenuItem href="#/drugInteractionSearch">Drug Interactions</HeaderMenuItem>)
                :
                <Fragment/>
            }
            {
              hasIvCompatibility
                ?
                (<HeaderMenuItem href="#/ivCompatibilitySearch">IV Compatibility</HeaderMenuItem>)
                :
                <Fragment/>
            }
            {
              showMdxGenAIPage
				        ?
                (<HeaderMenuItem href="#/gen-ai">MDX GenAI</HeaderMenuItem>)
                :
                <Fragment/>
            }
          </HeaderNavigation>
          <HeaderGlobalBar onClick={() => isSideNavExpanded && onClickSideNavExpand()}>
            <Route render = {(routeProps) => <CommonOverflowMenu
              current_page_route = {current_route}
              {...routeProps}
            />} />
          </HeaderGlobalBar>
        </Header>
        <SideNav
          aria-label = "Side navigation"
          expanded = {isSideNavExpanded}
          isPersistent = {false}
          onOverlayClick = {onClickSideNavExpand}
          isSameLocationMenuClicked = {isSameLocationMenuClicked}
        >
          <SideNavItems className="side-nav-menu-item">
            <HeaderSideNavItems>
              { searchAsHomePage && (
              <HeaderMenuItem href={chatBotAsHomepage ? '#/chatbot' : '#/home'} onClick={(event) => closeSideNav(event, props, isSideNavExpanded, onClickSideNavExpand)}>Home</HeaderMenuItem>)
              }         
              {
                hasDrugInteractions
                  ?
                  (<HeaderMenuItem href="#/drugInteractionSearch" onClick={(event) => closeSideNav(event, props, isSideNavExpanded, onClickSideNavExpand)}>Drug Interactions</HeaderMenuItem>)
                  :
                  <Fragment/>
              }
              {
                hasIvCompatibility
                  ?
                  (<HeaderMenuItem href="#/ivCompatibilitySearch" onClick={(event) => closeSideNav(event, props, isSideNavExpanded, onClickSideNavExpand)}>IV Compatibility</HeaderMenuItem>)
                  :
                  <Fragment/>
              }
              {
                showMdxGenAIPage
                  ?
                  (<HeaderMenuItem href="#/gen-ai" onClick={(event) => closeSideNav(event, props, isSideNavExpanded, onClickSideNavExpand)}>MDX GenAI</HeaderMenuItem>)
				          :
                  <Fragment/>
              }
              
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </>
    )}
    />
  )
}
